import {
  BriefVideosWithVariantsFieldsFragment as IBrief,
  BriefFieldsWithVideosFieldsFragment as IBriefVideos,
} from "internal/shared/types/graphql-api";
import { IUploadParsedFilename } from "internal/shared/types/upload";
import textualize from "internal/shared/utils/textualize";
import ASPECT_RATIOS from "shared/constants/aspectRatios";
import { AD_UNIT_MAP } from "shared/utils/getAdUnitDetails/getAdUnitDetails";

// [A-Z]{3,4} - Three or four uppercase characters
// _
// \d{4}|X{4}|X{3}\d{1}|X{2}\d{2} - 4 numbers or XXXX/XXX1/XX12/ (for videos without an ID yet)
// _
// [a-z,_]* - Any length string (lowercase, with underscores, optional)
//
// \d{1,2}x\d{1,2} - 1 or 2 numbers, x, 1 or 2 numbers
// _
// [a-z]{1,2}-[A-Z]{1,2}|none - two lowercase letters, -, two uppercase letters OR the word "none" for no language
// _
// \d{2} - two digit number
// .
// (?:mov|mp4) - mp4 or mov
const FILENAME_REGEX =
  /^([A-Z]{3,4}_(?:\d{4}|X{4}|X{3}\d{1}|X{2}\d{2}))_([a-z,_]*?[a-z]*?)_?(\d{1,2}x\d{1,2})_((?:[a-z]{1,2}-[A-Z]{1,2}|none))_?(\d{2})?\.(?:mov|mp4)/;

export const INVALID_FILENAME_ERROR = `${textualize(
  "upload.submission.incorrectFilenames.error",
)}
${textualize("upload.submission.incorrectFilenames.mustFollow")}
${textualize("upload.submission.incorrectFilenames.example")}`;

export const INVALID_AD_UNIT_ERROR = textualize(
  "upload.submission.invalidAdUnit",
) as string;

export const INVALID_AD_UNIT_ASPECT_RATIO_PAIRING_ERROR = textualize(
  "upload.submission.invalidAdUnitAspectRatioPairing",
) as string;

export const INVALID_ASPECT_RATIO_ERROR = textualize(
  "upload.submission.invalidAspectRatio",
) as string;

export const INVALID_LANGUAGE_ERROR = textualize(
  "upload.submission.invalidLanguage",
) as string;

export const INVALID_SEQUENCE_AD_UNIT_ERROR = textualize(
  "upload.submission.invalidSequenceAdUnit",
) as string;

export const INVALID_SEQUENCE_RATIO_ERROR = textualize(
  "upload.submission.invalidSequenceRatio",
) as string;

export const INVALID_SHORTHASH_ERROR = textualize(
  "upload.submission.invalidShortHash",
) as string;

export const NON_EXISTENT_VIDEO_ERROR = textualize(
  "upload.submission.nonExistentVideoError",
) as string;

export const REQUIRED_AD_UNIT_FOR_RATIO_ERROR = textualize(
  "upload.submission.requiredAdUnitForRatio",
) as string;

export const EXPLICIT_AD_UNIT_FOR_RATIO_ERROR = textualize(
  "upload.submission.explicitAdUnitForRatio",
) as string;

export const SEQUENCE_NUMBER_REQUIRED = textualize(
  "upload.submission.sequenceNumberRequired",
) as string;

const isSequenceEnabled = (adUnit: string) =>
  AD_UNIT_MAP[adUnit] && AD_UNIT_MAP[adUnit].sequenceEnabled;

const isInvalidSequenceRatio = (ratio: string) =>
  ![
    ASPECT_RATIOS.NINE_BY_SIXTEEN,
    ASPECT_RATIOS.ONE_BY_ONE,
    ASPECT_RATIOS.TWO_BY_THREE,
  ].includes(ratio.replace("x", ":"));

const isInvalidAdUnit = (adUnit: string, aspectRatioAdUnits: any[]) => {
  const ALLOWED_AD_UNITS = new Set(
    aspectRatioAdUnits.map(
      (aspectRatioAdUnit: any) => aspectRatioAdUnit.adUnit,
    ),
  );
  return !ALLOWED_AD_UNITS.has(adUnit);
};

const isInvalidAdUnitAspectRatioPairing = (
  adUnit: string,
  ratio: string,
  aspectRatioAdUnits: any[],
) =>
  !aspectRatioAdUnits.some(
    (aspectRatioAdUnit: any) =>
      aspectRatioAdUnit.adUnit === adUnit &&
      aspectRatioAdUnit.aspectRatio === ratio.replace("x", ":"),
  );

const isInvalidAspectRatio = (ratio: string, aspectRatioAdUnits: any[]) => {
  const ALLOWED_ASPECT_RATIOS = new Set(
    aspectRatioAdUnits.map(
      (aspectRatioAdUnit: any) => aspectRatioAdUnit.aspectRatio,
    ),
  );
  return !ALLOWED_ASPECT_RATIOS.has(ratio.replace("x", ":"));
};

const isInvalidLanguage = (language: string, allowedLanguages: string[] = []) =>
  !Object.values(allowedLanguages).includes(language);

const isNonExistentVideo = (videoShorthash: string, videos: IBriefVideos[]) =>
  !videos.map((video) => video.shortHash).includes(videoShorthash);

const missingRequiredAdUnit = (ratio: string, adUnit: string) =>
  ratio.replace("x", ":") === ASPECT_RATIOS.NINE_BY_SIXTEEN && !adUnit;

const wronglyExplicitAdUnit = (ratio: string, adUnit: string) =>
  ratio.replace("x", ":") !== ASPECT_RATIOS.NINE_BY_SIXTEEN && adUnit;

const allBriefAspectRatioAdUnitsRequireSequence = (
  ratio: string,
  aspectRatioAdUnits: any[],
) =>
  aspectRatioAdUnits
    ?.filter((item) => ratio.replace("x", ":") === item?.aspectRatio)
    .every((item) => isSequenceEnabled(item.adUnit));

function uploadsPreflight(
  file: File,
  brief: IBrief,
): [boolean, string, IUploadParsedFilename | undefined] {
  const parsedName = FILENAME_REGEX.exec(file.name);

  if (!parsedName || parsedName.length < 6) {
    return [true, INVALID_FILENAME_ERROR, undefined];
  }

  const [, videoShortHash, adUnit, ratio, language, sequenceNumber] =
    parsedName;

  if (!videoShortHash.startsWith(brief.shortHash)) {
    return [true, INVALID_SHORTHASH_ERROR, undefined];
  }

  if (
    !videoShortHash.includes("_XX") &&
    isNonExistentVideo(videoShortHash, brief.videos!)
  ) {
    return [true, NON_EXISTENT_VIDEO_ERROR, undefined];
  }

  if (sequenceNumber && isInvalidSequenceRatio(ratio)) {
    return [true, INVALID_SEQUENCE_RATIO_ERROR, undefined];
  }

  if (wronglyExplicitAdUnit(ratio, adUnit)) {
    return [true, EXPLICIT_AD_UNIT_FOR_RATIO_ERROR, undefined];
  }

  if (adUnit && sequenceNumber && !isSequenceEnabled(adUnit)) {
    return [true, INVALID_SEQUENCE_AD_UNIT_ERROR, undefined];
  }

  if (adUnit && isInvalidAdUnit(adUnit, brief.aspectRatioAdUnits!)) {
    return [true, INVALID_AD_UNIT_ERROR, undefined];
  }

  if (isInvalidAspectRatio(ratio, brief.aspectRatioAdUnits!)) {
    return [true, INVALID_ASPECT_RATIO_ERROR, undefined];
  }

  if (
    adUnit &&
    isInvalidAdUnitAspectRatioPairing(adUnit, ratio, brief.aspectRatioAdUnits!)
  ) {
    return [true, INVALID_AD_UNIT_ASPECT_RATIO_PAIRING_ERROR, undefined];
  }

  if (missingRequiredAdUnit(ratio, adUnit)) {
    return [true, REQUIRED_AD_UNIT_FOR_RATIO_ERROR, undefined];
  }

  if (isInvalidLanguage(language, brief.languages as string[])) {
    return [true, INVALID_LANGUAGE_ERROR, undefined];
  }

  if (
    ((!adUnit &&
      allBriefAspectRatioAdUnitsRequireSequence(
        ratio,
        brief.aspectRatioAdUnits!,
      )) ||
      (adUnit && isSequenceEnabled(adUnit))) &&
    !sequenceNumber
  ) {
    return [true, SEQUENCE_NUMBER_REQUIRED, undefined];
  }

  return [
    false,
    "",
    {
      adUnit,
      language,
      ratio: ratio.replace("x", ":"),
      sequenceNumber,
      videoShortHash,
    } as IUploadParsedFilename,
  ];
}

export default uploadsPreflight;
