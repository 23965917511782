import { lazy } from "react";
import { IRouteGroup } from "shared/types/routes";

const BriefingForm = lazy(() => import("./pages/BriefingForm"));

export const routes: IRouteGroup = {
  name: "briefing",
  routes: [
    {
      authRequired: true,
      component: BriefingForm,
      exact: true,
      path: "/:briefShortHash/:type?",
    },
  ],
};

export default routes;
