import { Link } from "react-router-dom";
import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import smallTextStyles from "shared/styling/SmallText";
import styled, { css } from "styled-components";

export const ellipsis = css`
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BrandName = styled.div`
  ${ellipsis}
  ${smallTextStyles}
`;

export const Container = styled.div`
  height: 100%;
  padding: 0.5rem 12px;
`;

export const Details = styled.div`
  flex: 1;
  min-width: 0;
`;

export const JobReference = styled.div`
  ${ellipsis}
  font-size: .75rem;
  margin-left: 1rem;
`;

export const ShortHash = styled.div`
  font-size: 0.625rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  letter-spacing: 0.005rem;
`;

// Filter out `inactive` prop to avoid passing it to `Link`
export const StyledLink = styled(({ inactive, ...rest }) => (
  <Link {...rest} />
))<{
  inactive?: boolean;
}>`
  border-radius: 1px;
  box-shadow: 0 0 0 2px
    ${(props) => (props.inactive === false ? COLORS.BLUE : "transparent")};
  color: ${COLORS.WHITE};
  display: block;
  outline: none;
  opacity: ${(props) => (props.inactive === true ? 0.5 : 1)};
  text-decoration: none;
  transition: all 0.3s ease;
`;

export const Title = styled.div`
  ${ellipsis}
  font-size: .75rem;
  margin-top: 0.25rem;
`;

export const TopDetails = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
