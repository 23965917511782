import Partners from "shared/components/MobilePreviewPartners";
import { IProps as IMobilePreviewProps } from "shared/components/MobilePreviewPartners/types";
import AD_UNITS from "shared/constants/adUnits";
import ASPECT_RATIOS from "shared/constants/aspectRatios";
import AmazonIcon from "shared/images/icons/platforms/amazon.svg";
import FacebookIcon from "shared/images/icons/platforms/facebook.svg";
import FramelessIcon from "shared/images/icons/platforms/frameless.svg";
import GoogleIcon from "shared/images/icons/platforms/google.svg";
import InstagramIcon from "shared/images/icons/platforms/instagram.svg";
import LinkedInIcon from "shared/images/icons/platforms/linkedin.svg";
import PinterestIcon from "shared/images/icons/platforms/pinterest.svg";
import QuibiIcon from "shared/images/icons/platforms/quibi.svg";
import RedditIcon from "shared/images/icons/platforms/reddit.svg";
import SnapchatIcon from "shared/images/icons/platforms/snapchat.svg";
import TikTokIcon from "shared/images/icons/platforms/tiktok.svg";
import TwitterIcon from "shared/images/icons/platforms/twitter.svg";
import YouTubeIcon from "shared/images/icons/platforms/youtube.svg";
import AmazonPhoneTop from "shared/images/phoneTops/amazon-phone-top.svg";
import FacebookPhoneTop from "shared/images/phoneTops/facebook-phone-top.svg";
import InstagramPhoneTop from "shared/images/phoneTops/instagram-phone-top.svg";
import LinkedInPhoneTop from "shared/images/phoneTops/linkedin-phone-top.svg";
import PinterestPhoneTop from "shared/images/phoneTops/pinterest-phone-top.svg";
import QuibiPhoneTop from "shared/images/phoneTops/quibi-phone-top.svg";
import RedditPhoneTop from "shared/images/phoneTops/reddit-phone-top.svg";
import SnapchatPhoneTop from "shared/images/phoneTops/snapchat-phone-top.svg";
import TikTokPhoneTop from "shared/images/phoneTops/tiktok-phone-top.svg";
import TwitterPhoneTop from "shared/images/phoneTops/twitter-phone-top.svg";
import YouTubePhoneTop from "shared/images/phoneTops/youtube-phone-top.svg";

export interface IAdUnitMap {
  [x: string]: {
    acceptedRatios: string[];
    brand: {
      name: string;
      icon: any;
      phoneTop: any;
    };
    fullName: string;
    mobilePreview: ({
      brand,
      children,
      logo,
    }: IMobilePreviewProps) => JSX.Element;
    showPhone: boolean;
    sequenceEnabled: boolean;
    type: string;
  };
}

export const AD_UNIT_MAP: IAdUnitMap = {
  [AD_UNITS.AMAZON.OTT.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.SIXTEEN_BY_NINE],
    brand: {
      icon: AmazonIcon,
      name: AD_UNITS.AMAZON.BRAND,
      phoneTop: undefined,
    },
    fullName: `${
      AD_UNITS.AMAZON.BRAND
    } ${AD_UNITS.AMAZON.OTT.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Display.Display,
    showPhone: false,
    sequenceEnabled: false,
    type: AD_UNITS.AMAZON.OTT.TYPE,
  },
  [AD_UNITS.AMAZON.POSTS.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.FOUR_BY_THREE,
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
    ],
    brand: {
      icon: AmazonIcon,
      name: AD_UNITS.AMAZON.BRAND,
      phoneTop: AmazonPhoneTop,
    },
    fullName: `${
      AD_UNITS.AMAZON.BRAND
    } ${AD_UNITS.AMAZON.POSTS.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Amazon.AmazonPosts,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.AMAZON.POSTS.TYPE,
  },
  [AD_UNITS.AMAZON.PRODUCT_DETAIL.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.FOUR_BY_FIVE,
      ASPECT_RATIOS.FOUR_BY_THREE,
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
      ASPECT_RATIOS.TWO_BY_THREE,
    ],
    brand: {
      icon: AmazonIcon,
      name: AD_UNITS.AMAZON.BRAND,
      phoneTop: AmazonPhoneTop,
    },
    fullName: `${
      AD_UNITS.AMAZON.BRAND
    } ${AD_UNITS.AMAZON.PRODUCT_DETAIL.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Amazon.AmazonProductDetail,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.AMAZON.PRODUCT_DETAIL.TYPE,
  },
  [AD_UNITS.AMAZON.SPONSORED_BRANDS.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.FOUR_BY_THREE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
    ],
    brand: {
      icon: AmazonIcon,
      name: AD_UNITS.AMAZON.BRAND,
      phoneTop: AmazonPhoneTop,
    },
    fullName: `${
      AD_UNITS.AMAZON.BRAND
    } ${AD_UNITS.AMAZON.SPONSORED_BRANDS.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Amazon.AmazonSponsoredBrands,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.AMAZON.SPONSORED_BRANDS.TYPE,
  },
  [AD_UNITS.DISPLAY.DIGITAL_OOH.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.FOUR_BY_FIVE,
      ASPECT_RATIOS.FOUR_BY_THREE,
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
      ASPECT_RATIOS.TWO_BY_THREE,
    ],
    brand: {
      icon: FramelessIcon,
      name: AD_UNITS.DISPLAY.BRAND,
      phoneTop: undefined,
    },
    fullName: `${AD_UNITS.DISPLAY.BRAND} ${AD_UNITS.DISPLAY.DIGITAL_OOH.TYPE}`,
    mobilePreview: Partners.Display.Display,
    showPhone: false,
    sequenceEnabled: false,
    type: AD_UNITS.DISPLAY.DIGITAL_OOH.TYPE,
  },
  [AD_UNITS.DISPLAY.SINGLE.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.FOUR_BY_FIVE,
      ASPECT_RATIOS.FOUR_BY_THREE,
      ASPECT_RATIOS.NINE_BY_SIXTEEN,
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
      ASPECT_RATIOS.TWO_BY_THREE,
    ],
    brand: {
      icon: FramelessIcon,
      name: AD_UNITS.DISPLAY.BRAND,
      phoneTop: undefined,
    },
    // Don't need BRAND as `Display Display is redundant
    fullName: `${AD_UNITS.DISPLAY.SINGLE.TYPE}`,
    mobilePreview: Partners.Display.Display,
    showPhone: false,
    sequenceEnabled: false,
    type: AD_UNITS.DISPLAY.SINGLE.TYPE,
  },
  [AD_UNITS.DISPLAY.SEQUENCE.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.NINE_BY_SIXTEEN,
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.TWO_BY_THREE,
    ],
    brand: {
      icon: FramelessIcon,
      name: AD_UNITS.DISPLAY.BRAND,
      phoneTop: undefined,
    },
    fullName: `${AD_UNITS.DISPLAY.SEQUENCE.TYPE}`,
    mobilePreview: Partners.Display.Display,
    showPhone: false,
    sequenceEnabled: true,
    type: AD_UNITS.DISPLAY.SEQUENCE.TYPE,
  },
  [AD_UNITS.FACEBOOK.COLLECTION_AD.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.ONE_BY_ONE],
    brand: {
      icon: FacebookIcon,
      name: AD_UNITS.FACEBOOK.BRAND,
      phoneTop: FacebookPhoneTop,
    },
    fullName: `${AD_UNITS.FACEBOOK.BRAND} ${AD_UNITS.FACEBOOK.COLLECTION_AD.TYPE}`,
    mobilePreview: Partners.Facebook.FacebookCollection,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.FACEBOOK.COLLECTION_AD.TYPE,
  },
  [AD_UNITS.FACEBOOK.INFEED.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.TWO_BY_THREE,
      ASPECT_RATIOS.FOUR_BY_FIVE,
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
    ],
    brand: {
      icon: FacebookIcon,
      name: AD_UNITS.FACEBOOK.BRAND,
      phoneTop: FacebookPhoneTop,
    },
    fullName: `${
      AD_UNITS.FACEBOOK.BRAND
    } ${AD_UNITS.FACEBOOK.INFEED.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Facebook.FacebookInfeed,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.FACEBOOK.INFEED.TYPE,
  },
  [AD_UNITS.FACEBOOK.INFEED_CAROUSEL.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.ONE_BY_ONE],
    brand: {
      icon: FacebookIcon,
      name: AD_UNITS.FACEBOOK.BRAND,
      phoneTop: FacebookPhoneTop,
    },
    fullName: `${
      AD_UNITS.FACEBOOK.BRAND
    } ${AD_UNITS.FACEBOOK.INFEED_CAROUSEL.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Facebook.FacebookCarouselInfeed,
    showPhone: true,
    sequenceEnabled: true,
    type: AD_UNITS.FACEBOOK.INFEED_CAROUSEL.TYPE,
  },
  [AD_UNITS.FACEBOOK.STORIES.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: FacebookIcon,
      name: AD_UNITS.FACEBOOK.BRAND,
      phoneTop: FacebookPhoneTop,
    },
    fullName: `${
      AD_UNITS.FACEBOOK.BRAND
    } ${AD_UNITS.FACEBOOK.STORIES.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Facebook.FacebookStories,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.FACEBOOK.STORIES.TYPE,
  },
  [AD_UNITS.FACEBOOK.STORIES_CAROUSEL.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: FacebookIcon,
      name: AD_UNITS.FACEBOOK.BRAND,
      phoneTop: FacebookPhoneTop,
    },
    fullName: `${
      AD_UNITS.FACEBOOK.BRAND
    } ${AD_UNITS.FACEBOOK.STORIES_CAROUSEL.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Facebook.FacebookStories,
    showPhone: true,
    sequenceEnabled: true,
    type: AD_UNITS.FACEBOOK.STORIES_CAROUSEL.TYPE,
  },
  [AD_UNITS.GOOGLE.APP_CAMPAIGN.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
      ASPECT_RATIOS.TWO_BY_THREE,
    ],
    brand: {
      icon: GoogleIcon,
      name: AD_UNITS.GOOGLE.BRAND,
      phoneTop: undefined,
    },
    fullName: `${
      AD_UNITS.GOOGLE.BRAND
    } ${AD_UNITS.GOOGLE.APP_CAMPAIGN.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Display.Display,
    showPhone: false,
    sequenceEnabled: false,
    type: AD_UNITS.GOOGLE.APP_CAMPAIGN.TYPE,
  },
  [AD_UNITS.INSTAGRAM.INFEED.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.FOUR_BY_FIVE,
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
    ],
    brand: {
      icon: InstagramIcon,
      name: AD_UNITS.INSTAGRAM.BRAND,
      phoneTop: InstagramPhoneTop,
    },
    fullName: `${
      AD_UNITS.INSTAGRAM.BRAND
    } ${AD_UNITS.INSTAGRAM.INFEED.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Instagram.InstagramInfeed,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.INSTAGRAM.INFEED.TYPE,
  },
  [AD_UNITS.INSTAGRAM.INFEED_CAROUSEL.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.ONE_BY_ONE],
    brand: {
      icon: InstagramIcon,
      name: AD_UNITS.INSTAGRAM.BRAND,
      phoneTop: InstagramPhoneTop,
    },
    fullName: `${
      AD_UNITS.INSTAGRAM.BRAND
    } ${AD_UNITS.INSTAGRAM.INFEED_CAROUSEL.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Instagram.InstagramCarouselInfeed,
    showPhone: true,
    sequenceEnabled: true,
    type: AD_UNITS.INSTAGRAM.INFEED_CAROUSEL.TYPE,
  },
  [AD_UNITS.INSTAGRAM.REELS.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: InstagramIcon,
      name: AD_UNITS.INSTAGRAM.BRAND,
      phoneTop: InstagramPhoneTop,
    },
    fullName: `${AD_UNITS.INSTAGRAM.BRAND} ${AD_UNITS.INSTAGRAM.REELS.TYPE}`,
    mobilePreview: Partners.Instagram.InstagramReels,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.INSTAGRAM.REELS.TYPE,
  },
  [AD_UNITS.INSTAGRAM.STORIES.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: InstagramIcon,
      name: AD_UNITS.INSTAGRAM.BRAND,
      phoneTop: InstagramPhoneTop,
    },
    fullName: `${
      AD_UNITS.INSTAGRAM.BRAND
    } ${AD_UNITS.INSTAGRAM.STORIES.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Instagram.InstagramStories,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.INSTAGRAM.STORIES.TYPE,
  },
  [AD_UNITS.INSTAGRAM.STORIES_CAROUSEL.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: InstagramIcon,
      name: AD_UNITS.INSTAGRAM.BRAND,
      phoneTop: InstagramPhoneTop,
    },
    fullName: `${
      AD_UNITS.INSTAGRAM.BRAND
    } ${AD_UNITS.INSTAGRAM.STORIES_CAROUSEL.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Instagram.InstagramStories,
    showPhone: true,
    sequenceEnabled: true,
    type: AD_UNITS.INSTAGRAM.STORIES_CAROUSEL.TYPE,
  },
  [AD_UNITS.LINKED_IN.INFEED.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.ONE_BY_ONE, ASPECT_RATIOS.SIXTEEN_BY_NINE],
    brand: {
      icon: LinkedInIcon,
      name: AD_UNITS.LINKED_IN.BRAND,
      phoneTop: LinkedInPhoneTop,
    },
    fullName: `${
      AD_UNITS.LINKED_IN.BRAND
    } ${AD_UNITS.LINKED_IN.INFEED.TYPE.toLowerCase()}`,
    mobilePreview: Partners.LinkedIn.LinkedInInfeed,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.LINKED_IN.INFEED.TYPE,
  },
  [AD_UNITS.PINTEREST.CAROUSEL.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.TWO_BY_THREE],
    brand: {
      icon: PinterestIcon,
      name: AD_UNITS.PINTEREST.BRAND,
      phoneTop: PinterestPhoneTop,
    },
    fullName: `${AD_UNITS.PINTEREST.BRAND} ${AD_UNITS.PINTEREST.CAROUSEL.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Pinterest.PinterestCarousel,
    showPhone: true,
    sequenceEnabled: true,
    type: AD_UNITS.PINTEREST.CAROUSEL.TYPE,
  },
  [AD_UNITS.PINTEREST.MAX_WIDTH.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.SIXTEEN_BY_NINE, ASPECT_RATIOS.ONE_BY_ONE],
    brand: {
      icon: PinterestIcon,
      name: AD_UNITS.PINTEREST.BRAND,
      phoneTop: PinterestPhoneTop,
    },
    fullName: `${
      AD_UNITS.PINTEREST.BRAND
    } ${AD_UNITS.PINTEREST.MAX_WIDTH.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Pinterest.PinterestMax,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.PINTEREST.MAX_WIDTH.TYPE,
  },
  [AD_UNITS.PINTEREST.PREMIERE_SPOTLIGHT.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.SIXTEEN_BY_NINE, ASPECT_RATIOS.ONE_BY_ONE],
    brand: {
      icon: PinterestIcon,
      name: AD_UNITS.PINTEREST.BRAND,
      phoneTop: PinterestPhoneTop,
    },
    fullName: `${
      AD_UNITS.PINTEREST.BRAND
    } ${AD_UNITS.PINTEREST.PREMIERE_SPOTLIGHT.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Pinterest.PinterestPremiereSpotlight,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.PINTEREST.PREMIERE_SPOTLIGHT.TYPE,
  },
  [AD_UNITS.PINTEREST.STANDARD_WIDTH.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.NINE_BY_SIXTEEN,
      ASPECT_RATIOS.TWO_BY_THREE,
      ASPECT_RATIOS.ONE_BY_ONE,
    ],
    brand: {
      icon: PinterestIcon,
      name: AD_UNITS.PINTEREST.BRAND,
      phoneTop: PinterestPhoneTop,
    },
    fullName: `${
      AD_UNITS.PINTEREST.BRAND
    } ${AD_UNITS.PINTEREST.STANDARD_WIDTH.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Pinterest.PinterestStandard,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.PINTEREST.STANDARD_WIDTH.TYPE,
  },
  [AD_UNITS.QUIBI.DUAL.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: QuibiIcon,
      name: AD_UNITS.QUIBI.BRAND,
      phoneTop: QuibiPhoneTop,
    },
    fullName: `${
      AD_UNITS.QUIBI.BRAND
    } ${AD_UNITS.QUIBI.DUAL.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Display.Display,
    showPhone: false,
    sequenceEnabled: false,
    type: AD_UNITS.QUIBI.DUAL.TYPE,
  },
  [AD_UNITS.QUIBI.SINGLE.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.NINE_BY_SIXTEEN,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
    ],
    brand: {
      icon: QuibiIcon,
      name: AD_UNITS.QUIBI.BRAND,
      phoneTop: QuibiPhoneTop,
    },
    fullName: `${
      AD_UNITS.QUIBI.BRAND
    } ${AD_UNITS.QUIBI.SINGLE.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Display.Display,
    showPhone: false,
    sequenceEnabled: false,
    type: AD_UNITS.QUIBI.SINGLE.TYPE,
  },
  [AD_UNITS.REDDIT.PROMOTED_POST.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.FOUR_BY_FIVE,
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
    ],
    brand: {
      icon: RedditIcon,
      name: AD_UNITS.REDDIT.BRAND,
      phoneTop: RedditPhoneTop,
    },
    fullName: `${AD_UNITS.REDDIT.BRAND} ${AD_UNITS.REDDIT.PROMOTED_POST.TYPE}`,
    mobilePreview: Partners.Reddit.RedditPromotedPost,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.REDDIT.PROMOTED_POST.TYPE,
  },
  [AD_UNITS.SNAP.AD.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: SnapchatIcon,
      name: AD_UNITS.SNAP.BRAND,
      phoneTop: SnapchatPhoneTop,
    },
    fullName: `${AD_UNITS.SNAP.BRAND} ${AD_UNITS.SNAP.AD.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Snapchat.SnapAd,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.SNAP.AD.TYPE,
  },
  [AD_UNITS.SNAP.APP_INSTALL.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: SnapchatIcon,
      name: AD_UNITS.SNAP.BRAND,
      phoneTop: SnapchatPhoneTop,
    },
    fullName: `${
      AD_UNITS.SNAP.BRAND
    } ${AD_UNITS.SNAP.APP_INSTALL.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Snapchat.AppAd,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.SNAP.APP_INSTALL.TYPE,
  },
  [AD_UNITS.SNAP.COLLECTION_AD.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: SnapchatIcon,
      name: AD_UNITS.SNAP.BRAND,
      phoneTop: SnapchatPhoneTop,
    },
    fullName: `${
      AD_UNITS.SNAP.BRAND
    } ${AD_UNITS.SNAP.COLLECTION_AD.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Snapchat.CollectionAd,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.SNAP.COLLECTION_AD.TYPE,
  },
  [AD_UNITS.SNAP.STORY.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: SnapchatIcon,
      name: AD_UNITS.SNAP.BRAND,
      phoneTop: SnapchatPhoneTop,
    },
    fullName: `${
      AD_UNITS.SNAP.BRAND
    } ${AD_UNITS.SNAP.STORY.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Snapchat.StoryAd,
    showPhone: true,
    sequenceEnabled: true,
    type: AD_UNITS.SNAP.STORY.TYPE,
  },
  [AD_UNITS.SNAP.STORY_APP_INSTALL.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: SnapchatIcon,
      name: AD_UNITS.SNAP.BRAND,
      phoneTop: SnapchatPhoneTop,
    },
    fullName: `${
      AD_UNITS.SNAP.BRAND
    } ${AD_UNITS.SNAP.STORY_APP_INSTALL.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Snapchat.StoryAppAd,
    showPhone: true,
    sequenceEnabled: true,
    type: AD_UNITS.SNAP.STORY_APP_INSTALL.TYPE,
  },
  [AD_UNITS.TIKTOK.INFEED_NATIVE.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: TikTokIcon,
      name: AD_UNITS.TIKTOK.BRAND,
      phoneTop: TikTokPhoneTop,
    },
    fullName: `${
      AD_UNITS.TIKTOK.BRAND
    } ${AD_UNITS.TIKTOK.INFEED_NATIVE.TYPE.toLowerCase()}`,
    mobilePreview: Partners.TikTok.TikTokInfeed,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.TIKTOK.INFEED_NATIVE.TYPE,
  },
  [AD_UNITS.TIKTOK.TOPVIEW.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: TikTokIcon,
      name: AD_UNITS.TIKTOK.BRAND,
      phoneTop: TikTokPhoneTop,
    },
    fullName: `${AD_UNITS.TIKTOK.BRAND} ${AD_UNITS.TIKTOK.TOPVIEW.TYPE}`,
    mobilePreview: Partners.TikTok.TikTokTopView,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.TIKTOK.TOPVIEW.TYPE,
  },
  [AD_UNITS.TWITTER.INFEED.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.ONE_BY_ONE, ASPECT_RATIOS.SIXTEEN_BY_NINE],
    brand: {
      icon: TwitterIcon,
      name: AD_UNITS.TWITTER.BRAND,
      phoneTop: TwitterPhoneTop,
    },
    fullName: `${
      AD_UNITS.TWITTER.BRAND
    } ${AD_UNITS.TWITTER.INFEED.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Twitter.TwitterInfeed,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.TWITTER.INFEED.TYPE,
  },
  [AD_UNITS.TWITTER.POLL.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.SIXTEEN_BY_NINE],
    brand: {
      icon: TwitterIcon,
      name: AD_UNITS.TWITTER.BRAND,
      phoneTop: TwitterPhoneTop,
    },
    fullName: `${
      AD_UNITS.TWITTER.BRAND
    } ${AD_UNITS.TWITTER.POLL.TYPE.toLowerCase()}`,
    mobilePreview: Partners.Twitter.TwitterPoll,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.TWITTER.POLL.TYPE,
  },
  [AD_UNITS.YOUTUBE.BUMPER.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.FOUR_BY_FIVE,
      ASPECT_RATIOS.NINE_BY_SIXTEEN,
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
    ],
    brand: {
      icon: YouTubeIcon,
      name: AD_UNITS.YOUTUBE.BRAND,
      phoneTop: YouTubePhoneTop,
    },
    fullName: `${
      AD_UNITS.YOUTUBE.BRAND
    } ${AD_UNITS.YOUTUBE.BUMPER.TYPE.toLowerCase()}`,
    mobilePreview: Partners.YouTube.YouTubeBumper,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.YOUTUBE.BUMPER.TYPE,
  },
  [AD_UNITS.YOUTUBE.SHORTS.MAP_VALUE]: {
    acceptedRatios: [ASPECT_RATIOS.NINE_BY_SIXTEEN],
    brand: {
      icon: YouTubeIcon,
      name: AD_UNITS.YOUTUBE.BRAND,
      phoneTop: YouTubePhoneTop,
    },
    fullName: `${
      AD_UNITS.YOUTUBE.BRAND
    } ${AD_UNITS.YOUTUBE.SHORTS.TYPE.toLowerCase()}`,
    mobilePreview: Partners.YouTube.YouTubeShorts,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.YOUTUBE.SHORTS.TYPE,
  },
  [AD_UNITS.YOUTUBE.TRUEVIEW.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.FOUR_BY_FIVE,
      ASPECT_RATIOS.NINE_BY_SIXTEEN,
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
    ],
    brand: {
      icon: YouTubeIcon,
      name: AD_UNITS.YOUTUBE.BRAND,
      phoneTop: YouTubePhoneTop,
    },
    fullName: `${
      AD_UNITS.YOUTUBE.BRAND
    } ${AD_UNITS.YOUTUBE.TRUEVIEW.TYPE.toLowerCase()}`,
    mobilePreview: Partners.YouTube.YouTubeTrueView,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.YOUTUBE.TRUEVIEW.TYPE,
  },
  [AD_UNITS.YOUTUBE.TRUEVIEW_FOR_ACTION.MAP_VALUE]: {
    acceptedRatios: [
      ASPECT_RATIOS.ONE_BY_ONE,
      ASPECT_RATIOS.SIXTEEN_BY_NINE,
      ASPECT_RATIOS.TWO_BY_THREE,
    ],
    brand: {
      icon: YouTubeIcon,
      name: AD_UNITS.YOUTUBE.BRAND,
      phoneTop: YouTubePhoneTop,
    },
    fullName: `${AD_UNITS.YOUTUBE.BRAND} ${AD_UNITS.YOUTUBE.TRUEVIEW_FOR_ACTION.TYPE}`,
    mobilePreview: Partners.YouTube.YouTubeTrueViewForAction,
    showPhone: true,
    sequenceEnabled: false,
    type: AD_UNITS.YOUTUBE.TRUEVIEW_FOR_ACTION.TYPE,
  },
};

export default (adUnit: string) => AD_UNIT_MAP[adUnit];
