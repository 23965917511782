import {
  InfeedContainerLight,
  Scrollable,
} from "shared/components/MobilePreviewPartners/shared";
import ASPECT_RATIOS from "shared/constants/aspectRatios";
import { IProps } from "../types";
import {
  CaptionedBrand,
  FullNextCard,
  StandardLeftFeed,
  StandardRightFeed,
  TopBoards,
} from "./elements";

import { ReactElement, useEffect, useState } from "react";
import {
  BlurredBGVideo,
  Carousel,
  FixedAllBottomBar,
  MaxVideoBlock,
  NonScrollable,
  PinterestColumn,
  PinterestLayout,
  SpotlightVideoBlock,
  StandardVideo,
  StyledCta,
  StyledFullBrand,
  StyledScrollable,
  StyledSpotlightBody,
  StyledSpotlightTopBar,
  StyledStandardBrand,
  VideoBlock,
} from "./styles";

export interface IBrandProps {
  brand?: string;
  caption?: string;
  logo?: string;
}

export const PinterestCarousel = ({ brand, children, itemIndex }: IProps) => (
  <InfeedContainerLight>
    <TopBoards />
    <Scrollable tabIndex={0}>
      <PinterestLayout>
        <PinterestColumn>
          <VideoBlock>
            <Carousel>
              <div
                style={{
                  transform: `translateX(-${(itemIndex ? itemIndex : 0) * 100}%)`, // Perspective fixes sub-pixel issues
                }}
              >
                {children}
              </div>
            </Carousel>
            <StyledCta
              currentVideo={itemIndex}
              noOfVideos={(children as any[]).length}
            />
            <CaptionedBrand brand={brand} />
          </VideoBlock>
          <StandardLeftFeed />
        </PinterestColumn>
        <PinterestColumn>
          <StandardRightFeed />
        </PinterestColumn>
      </PinterestLayout>
    </Scrollable>
    <FixedAllBottomBar />
  </InfeedContainerLight>
);

export const PinterestStandard = ({
  brand,
  caption,
  children,
  logo,
  videoId,
}: IProps) => (
  <InfeedContainerLight>
    <TopBoards />
    <Scrollable tabIndex={0}>
      <PinterestLayout>
        <PinterestColumn>
          <VideoBlock>
            <StandardVideo>{children}</StandardVideo>
            <StyledStandardBrand
              brand={brand}
              caption={caption}
              logo={logo}
              videoId={videoId}
            />
          </VideoBlock>
          <StandardLeftFeed />
        </PinterestColumn>
        <PinterestColumn>
          <StandardRightFeed />
        </PinterestColumn>
      </PinterestLayout>
    </Scrollable>
    <FixedAllBottomBar />
  </InfeedContainerLight>
);

export const PinterestMax = ({
  brand,
  caption,
  children,
  logo,
  videoId,
}: IProps) => (
  <InfeedContainerLight>
    <TopBoards />
    <StyledScrollable>
      <MaxVideoBlock>
        <StandardVideo>{children}</StandardVideo>
      </MaxVideoBlock>
      <StyledFullBrand
        brand={brand}
        caption={caption}
        logo={logo}
        videoId={videoId}
      />
      <FullNextCard />
    </StyledScrollable>
    <FixedAllBottomBar />
  </InfeedContainerLight>
);

export const PinterestPremiereSpotlight = ({
  aspectRatio,
  brand,
  children,
  isMuted,
}: IProps) => {
  const currentVideoSource = (children as ReactElement[])[0]?.props?.source;
  const [bgVideoSource, setBgVideoSource] = useState(currentVideoSource);

  useEffect(() => {
    setBgVideoSource(currentVideoSource);
  }, [currentVideoSource]);

  const nonSquare = aspectRatio === ASPECT_RATIOS.SIXTEEN_BY_NINE;

  return (
    <InfeedContainerLight>
      <NonScrollable>
        <StyledSpotlightTopBar isMuted={isMuted} />
        <SpotlightVideoBlock nonSquare={nonSquare}>
          {currentVideoSource && nonSquare && (
            <BlurredBGVideo
              autoPlay={false}
              controls={false}
              key={bgVideoSource}
              muted={true}
            >
              <source src={bgVideoSource} type="video/mp4" />
            </BlurredBGVideo>
          )}
          {children}
        </SpotlightVideoBlock>
        <StyledSpotlightBody brand={brand} />
      </NonScrollable>
      {!nonSquare && <FixedAllBottomBar />}
    </InfeedContainerLight>
  );
};
