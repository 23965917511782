import { lazy } from "react";
import { IRouteGroup } from "shared/types/routes";

const CreatorSelection = lazy(() => import("./pages/CreatorSelection"));

export const routes: IRouteGroup = {
  name: "creator-selection",
  routes: [
    {
      authRequired: true,
      component: CreatorSelection,
      exact: true,
      path: "/:briefShortHash",
    },
  ],
};

export default routes;
