import { lazy } from "react";
import { IRouteGroup } from "shared/types/routes";

const AwaitingDetails = lazy(() => import("./pages/AwaitingDetails"));
const CompletedPayments = lazy(() => import("./pages/CompletedPayments"));
const VerifiedPayments = lazy(() => import("./pages/VerifiedPayments"));

export const routes: IRouteGroup = {
  name: "payments",
  routes: [
    {
      authRequired: true,
      component: AwaitingDetails,
      exact: true,
      path: "/awaiting",
    },
    {
      authRequired: true,
      component: CompletedPayments,
      exact: true,
      path: "/completed",
    },
    {
      authRequired: true,
      component: VerifiedPayments,
      exact: true,
      path: "/verified",
    },
  ],
};

export default routes;
