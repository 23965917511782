import { lazy } from "react";
import { IRouteGroup } from "shared/types/routes";

const EditShowreel = lazy(() => import("./pages/EditShowreel"));
const SavedShowreels = lazy(() => import("./pages/SavedShowreels"));

export const GUID_REGEX =
  "[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}";

// These routes are used by the internal App and
// include redirects for the paths in the showreel App
// that used to live here
export const routes: IRouteGroup = {
  name: "showreel",
  routes: [
    {
      authRequired: true,
      component: EditShowreel,
      exact: true,
      fullscreen: true,
      path: `/:showreelGUID(${GUID_REGEX})/edit`,
    },
    {
      authRequired: true,
      component: SavedShowreels,
      exact: true,
      path: "/saved/:briefShortHash",
    },
  ],
};

export default routes;
