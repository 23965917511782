import { lazy } from "react";
import { IRouteGroup } from "shared/types/routes";

const Inspo = lazy(() => import("./pages/Inspo"));

export const routes: IRouteGroup = {
  name: "inspo",
  routes: [
    {
      authRequired: true,
      component: Inspo,
      exact: true,
      path: "",
    },
  ],
};

export default routes;
