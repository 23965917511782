import Auth from "shared/services/Auth/Auth";
import SessionService from "shared/services/Session/Session";

export interface IProps {
  auth: Auth;
  session: SessionService;
}

const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;

export const checkAndRefreshToken = async (
  auth: IProps["auth"],
  session: IProps["session"],
): Promise<void> => {
  const currentSession = session.getSession();
  const refreshToken = localStorage.getItem(session.getRefreshTokenName());

  if ((!currentSession && !refreshToken) || !refreshToken) {
    // If there is no current session and no refresh token, do nothing
    return;
  }

  const currentTime = Date.now();
  const expiresAt = currentSession?.expiresAt || 0;
  const isSessionExpiring =
    expiresAt - currentTime <= FIVE_MINUTES_IN_MILLISECONDS;
  const isSessionExpired = expiresAt < currentTime;

  try {
    if (refreshToken && !currentSession?.accessToken) {
      // If there is a refreshToken and no access token, refresh the access token and store it
      const result = await auth.refreshAccessToken(refreshToken);
      session.updateSession(result);
    } else if (
      isSessionExpiring ||
      isSessionExpired ||
      !session.isAuthenticated()
    ) {
      // If the session is expiring, expired, or not authenticated, refresh the access
      const result = await auth.refreshAccessToken(refreshToken!);
      session.updateSession(result);
    }
  } catch {
    // If there is an error, clear the session and log out
    auth.logout();
    session.clear();
  }
};

export default checkAndRefreshToken;
