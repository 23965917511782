import { Scrollable } from "shared/components/MobilePreviewPartners/shared";
import COLORS from "shared/constants/colors";
import styled from "styled-components";
import { IBrandProps } from "./Pinterest";
import {
  AllBottomBar,
  Cta,
  FullBrand,
  SpotlightBody,
  SpotlightTopBar,
  StandardBrand,
} from "./elements";

export const Carousel = styled.div`
  background: transparent;
  border-radius: 1rem 1rem 0 0;
  flex: none;
  overflow-x: hidden;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: transform 0.5s;

    video {
      /* Makes FF flexbox happy */
      flex: none;
      height: 100%;
      min-width: 100%;
      width: 0;
    }
  }
`;

// Pinterest Layout
export const StyledScrollable = styled(Scrollable)`
  background: ${COLORS.WHITE};
`;

export const PinterestLayout = styled.div`
  background: ${COLORS.WHITE};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

// Address
export const PinterestColumn = styled.div`
  width: 50%;
`;

export const VideoBlock = styled.div`
  border-radius: 0.5rem;
  margin: 0 4.5% 0 7.5%;
`;

export const MaxVideoBlock = styled.div`
  margin: 0 auto;
  width: 90%;
`;

export const StandardVideo = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  video {
    display: block;
  }
`;

export const FixedAllBottomBar = styled(AllBottomBar)`
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 3;
`;

export const StyledFullBrand = styled(FullBrand)<IBrandProps>`
  margin-top: ${(props) => (props.caption ? undefined : "-4%")};
`;

export const StyledStandardBrand = styled(StandardBrand)<IBrandProps>`
  margin-top: ${(props) => (props.caption ? undefined : "-8%")};
`;

export const BlurredBGVideo = styled.video`
  aspect-ratio: 1 / 1;
  filter: blur(5px);
  position: absolute;
  object-fit: cover;
  /* Stretch the background video beyond the visible bounds */
  height: 110% !important;
  width: 110% !important;
  transform: translateX(-5%) translateY(-5%);
`;

// Spotlight Specific
export const NonScrollable = styled(Scrollable)`
  background: ${COLORS.WHITE};
  overflow-y: hidden;
`;

export const SpotlightVideoBlock = styled.div<{ nonSquare?: boolean }>`
  aspect-ratio: 1 / 1;
  display: flex;
  position: relative;
  width: 100%;

  ${BlurredBGVideo} {
    z-index: 1;
  }

  video:last-child {
    margin: auto 0;
    transform: ${({ nonSquare }) => (nonSquare ? "translateY(10px)" : null)};
    z-index: 2;
  }
`;

export const StyledCta = styled(Cta)`
  margin-bottom: 2%;
`;

export const StyledSpotlightTopBar = styled(SpotlightTopBar)`
  position: absolute;
  top: 0;
  transform: translateY(-2rem);
  z-index: 3;
`;

export const StyledSpotlightBody = styled(SpotlightBody)`
  transform: translateY(-3rem);
  z-index: 3;
`;
