import HighlightTerm from "internal/shared/components/HighlightTerm";
import SearchTile, {
  IProps as ISearchTileProps,
} from "internal/system/components/SearchTile/SearchTile";
import IDS from "internal/system/constants/ids";
import { forwardRef } from "react";
import {
  Container,
  Details,
  JobReference,
  ShortHash,
  StyledLink,
  Title,
  TopDetails,
} from "./styles";

export interface IProps extends ISearchTileProps {
  briefID: string;
  jobReference: string;
  projectName?: string;
  shortHash: string;
  title: string;
  to: string;
}

const BriefSearchTile = forwardRef(
  (
    {
      briefID,
      className,
      id,
      imageURI,
      inactive,
      jobReference,
      onClick,
      onMouseEnter,
      onMouseLeave,
      projectName,
      searchTerm,
      shortHash,
      tabIndex,
      title,
      to,
    }: IProps,
    ref: React.MutableRefObject<HTMLAnchorElement>,
  ) => (
    <StyledLink
      className={className}
      id={id}
      inactive={inactive}
      innerRef={ref}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tabIndex={tabIndex}
      to={to}
    >
      <SearchTile imageURI={imageURI}>
        <Container>
          <TopDetails>
            <ShortHash id={`${IDS.SEARCH_TILE.SHORT_HASH}-${briefID}`}>
              <HighlightTerm term={searchTerm} text={shortHash} />
            </ShortHash>
            <JobReference id={`${IDS.SEARCH_TILE.JOB_REFERENCE}-${briefID}`}>
              <HighlightTerm term={searchTerm} text={jobReference} />
            </JobReference>
          </TopDetails>
          <Details>
            {projectName && (
              <Title id={`${IDS.SEARCH_TILE.PROJECT_NAME}-${briefID}`}>
                <HighlightTerm term={searchTerm} text={projectName} />
              </Title>
            )}
            <Title id={`${IDS.SEARCH_TILE.BRIEF_NAME}-${briefID}`}>
              <HighlightTerm term={searchTerm} text={title} />
            </Title>
          </Details>
        </Container>
      </SearchTile>
    </StyledLink>
  ),
);

export default BriefSearchTile;
