import { ApolloProvider } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import UploadsProvider from "internal/shared/components/uploads/UploadsProvider";
import Routes from "internal/web/components/Routes";
import DATADOG from "internal/web/constants/datadog";
import client from "internal/web/services/GraphQL/graphql-api/client";
import ldClient from "internal/web/services/LaunchDarkly/client";
import Session from "internal/web/services/Session";
import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route } from "react-router-dom";
import Analytics from "shared/components/Analytics";
import CrashReporterBoundary from "shared/components/CrashReporterBoundary";
import SqueezedError from "shared/components/errors/SqueezedError";
import UnrecoverableError from "shared/components/errors/UnrecoverableError";
import FeatureProvider from "shared/components/FeatureProvider";
import GlobalStyle from "shared/components/GlobalStyle";
import LDIdentifier from "shared/components/LDIdentifier";
import LoadingElement from "shared/components/LoadingElement";
import ModalProvider from "shared/components/ModalProvider";
import ScrollToTop from "shared/components/ScrollToTop";
import ToastContainer from "shared/components/ToastContainer";
import ToastProvider from "shared/components/ToastProvider";
import UpdateAvailableProvider from "shared/components/UpdateAvailableProvider";
import ANALYTICS from "shared/constants/analytics/config";
import Environment from "shared/types/environment";
import getDatadogBeforeSend from "shared/utils/getDatadogBeforeSend";
import getEnvironment from "shared/utils/getEnvironment";
import textualize from "shared/utils/textualize";

// Paths that we want to allow uploads on should match briefShortHash
const UPLOAD_PATHS = [
  "/curation/:briefShortHash/videos",
  "/curation/:briefShortHash/videos/:videoShortHash([A-Z]{3,4}_[0-9]{4})",
  "*",
];

function App() {
  const environment = getEnvironment();
  const segmentID = ANALYTICS[environment];

  if (environment !== Environment.Development) {
    datadogRum.init({
      allowedTracingUrls: DATADOG.CONFIG.ALLOWED_TRACING_URLS,
      applicationId: DATADOG.CONFIG.APPLICATION_ID,
      beforeSend: getDatadogBeforeSend(client, DATADOG),
      clientToken: DATADOG.CONFIG.CLIENT_TOKEN,
      defaultPrivacyLevel: DATADOG.CONFIG.DEFAULT_PRIVACY_LEVEL,
      env: environment,
      service: DATADOG.CONFIG.SERVICE,
      sessionSampleRate: DATADOG.CONFIG.SESSION_SAMPLE_RATE,
      sessionReplaySampleRate: DATADOG.CONFIG.SESSION_REPLAY_SAMPLE_RATE,
      site: DATADOG.CONFIG.SITE,
      trackUserInteractions: DATADOG.CONFIG.TRACK_USER_INTERACTIONS,
      version: `${process.env.VERSION}`,
    });
  }

  return (
    <BrowserRouter>
      <SqueezedError>
        <div>
          <CrashReporterBoundary fallback={UnrecoverableError}>
            <ApolloProvider client={client}>
              <Helmet
                defaultTitle={textualize("general.appTitle") as string}
                titleTemplate={`%s - ${textualize("general.appTitle")}`}
              />
              <FeatureProvider
                client={ldClient}
                loadingComponent={<LoadingElement />}
              >
                <CrashReporterBoundary fallback={UnrecoverableError}>
                  <ToastProvider>
                    <UpdateAvailableProvider>
                      <ToastContainer />
                      <ModalProvider>
                        <Suspense fallback={<LoadingElement />}>
                          <Route exact={true} path={UPLOAD_PATHS}>
                            <UploadsProvider>
                              <Routes />
                            </UploadsProvider>
                          </Route>
                        </Suspense>
                      </ModalProvider>
                    </UpdateAvailableProvider>
                  </ToastProvider>
                  {environment !== Environment.Development && (
                    <Analytics
                      isAuthenticated={Session.isAuthenticated}
                      segmentID={segmentID}
                    />
                  )}
                  <LDIdentifier client={ldClient} />
                </CrashReporterBoundary>
              </FeatureProvider>
            </ApolloProvider>
          </CrashReporterBoundary>
          <GlobalStyle />
          <ScrollToTop />
        </div>
      </SqueezedError>
    </BrowserRouter>
  );
}

export default App;
