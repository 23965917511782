import GuardedRoute from "internal/system/components/GuardedRoute";
import NotFound from "internal/web/pages/errors/NotFound";
import routes from "internal/web/routes";
import Session from "internal/web/services/Session";
import { Redirect, Route, Switch } from "react-router-dom";
import useFeature from "shared/hooks/useFeature";
import { IRouteProps } from "shared/types/routes";

export const Routes = () => {
  const oneAPIFlag = useFeature("oneAPICreatorIndex");

  return (
    <Switch>
      {routes.map((route: IRouteProps, i: number) => {
        const {
          authRequired,
          component: Component,
          updatedComponent: UpdatedComponent,
          ...rest
        } = route;
        return authRequired ? (
          <GuardedRoute key={i} {...rest}>
            {oneAPIFlag && UpdatedComponent ? (
              <UpdatedComponent />
            ) : (
              <Component />
            )}
          </GuardedRoute>
        ) : (
          <Route key={i} {...rest}>
            <Component />
          </Route>
        );
      })}
      {Session.isAuthenticated() ? (
        <Route key={routes.length + 1}>
          <NotFound />
        </Route>
      ) : (
        <Redirect to="/" />
      )}
    </Switch>
  );
};

export default Routes;
