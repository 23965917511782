import {
  ACCEPTED_MIME_TYPES,
  MAX_SIZE_BYTES,
  MAX_SIZE_MB,
} from "internal/shared/constants/uploads";
import textualize from "internal/shared/utils/textualize";
import { useCallback } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import isValidFileType from "shared/utils/isValidFileType";

interface IDropzoneProps {
  addUploads: (files: File[], startTime: string, failure?: string) => void;
  disabled?: boolean;
  onUploadStart?: () => void;
}

export const ACCEPTED_MIME_TYPES_ERROR = textualize(
  "upload.dropzone.errors.uploadFailureWithReason",
  {
    reason: `${textualize("upload.dropzone.errors.type")}`,
  },
) as string;

export const MAX_SIZE_ERROR = textualize(
  "upload.dropzone.errors.uploadFailureWithReason",
  {
    reason: `${textualize("upload.dropzone.errors.size", {
      maxSize: MAX_SIZE_MB,
    })}`,
  },
) as string;

export const GENERIC_ERROR = textualize(
  "upload.dropzone.errors.uploadFailure",
) as string;

export const rejectedFileError = (file: File) => {
  if (!isValidFileType(file, ACCEPTED_MIME_TYPES)) {
    return ACCEPTED_MIME_TYPES_ERROR;
  } else if (file.size > MAX_SIZE_BYTES) {
    return MAX_SIZE_ERROR;
  }
  return GENERIC_ERROR;
};

function useVideoDropzone({
  addUploads,
  disabled,
  onUploadStart,
}: IDropzoneProps) {
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (disabled) {
        return;
      }

      const startTime = new Date().getTime().toString();

      const lowercaseFileExtensions = acceptedFiles.map((file) => {
        const [name, extension] = file.name.split(".");
        const newName = `${name}.${extension.toLowerCase()}`;
        return new File([file], newName, { type: file.type });
      });

      addUploads(lowercaseFileExtensions, startTime);

      fileRejections.forEach((rejection) =>
        addUploads(
          [rejection.file],
          startTime,
          rejectedFileError(rejection.file),
        ),
      );

      if (onUploadStart) {
        onUploadStart();
      }
    },
    [addUploads, disabled, onUploadStart],
  );
  return useDropzone({
    accept: ACCEPTED_MIME_TYPES,
    disabled,
    maxSize: MAX_SIZE_BYTES,
    noKeyboard: true,
    onDrop,
    preventDropOnDocument: true,
    useFsAccessApi: false,
  });
}
export default useVideoDropzone;
