import { lazy } from "react";
import { IRouteGroup } from "shared/types/routes";

const CreatorBriefs = lazy(() => import("./pages/CreatorBriefs"));
const CreatorIndex = lazy(() => import("./pages/CreatorIndex"));
const CreatorAbout = lazy(() => import("./pages/CreatorAbout"));
const CreatorPayments = lazy(() => import("./pages/CreatorPayments"));
const CreatorVideos = lazy(() => import("./pages/CreatorVideos"));
const UnratedRookies = lazy(() => import("./pages/UnratedRookies"));

const OldCreatorAbout = lazy(() => import("./pages/graphql-api/CreatorAbout"));
const OldCreatorIndex = lazy(() => import("./pages/graphql-api/CreatorIndex"));

export const routes: IRouteGroup = {
  name: "creators",
  routes: [
    {
      authRequired: true,
      component: CreatorBriefs,
      path: "/:creatorExternalID/briefs",
    },
    {
      authRequired: true,
      component: OldCreatorAbout,
      path: "/:creatorExternalID/information",
      updatedComponent: CreatorAbout,
    },
    {
      authRequired: true,
      component: CreatorPayments,
      path: "/:creatorExternalID/payments",
    },
    {
      authRequired: true,
      component: CreatorVideos,
      path: "/:creatorExternalID/videos",
    },
    {
      authRequired: true,
      component: UnratedRookies,
      path: "/unrated-rookies",
    },
    {
      authRequired: true,
      component: OldCreatorIndex,
      exact: true,
      path: "/",
      updatedComponent: CreatorIndex,
    },
  ],
};

export default routes;
