import GlobalNav from "internal/system/components/GlobalNav";
import Session from "internal/web/services/Session";
import { useState } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import CheckSession from "shared/components/CheckSession";
import FetchViewer from "shared/components/FetchViewer";
import SessionDetails from "shared/components/SessionDetails";
import useFeature from "shared/hooks/useFeature";
import Auth from "shared/services/InternalAuth";

interface IGuardedRouteProps extends RouteProps {
  children?: React.ReactNode;
  fullscreen?: boolean;
}

function GuardedRoute({
  children,
  exact,
  fullscreen,
  location,
  path,
}: IGuardedRouteProps) {
  const refreshTokenFlag = useFeature("refreshTokens");
  const isUserAuthenticated = Session.isAuthenticated();
  const hasValidSession =
    Session.isRefreshTokenEnabled() || isUserAuthenticated;

  const [canRenderChildren, setCanRenderChildren] = useState(false);

  return (
    <Route exact={exact} path={path}>
      {!hasValidSession ? (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              referrerUri: location && location.pathname + location.search,
            },
          }}
        />
      ) : (
        <FetchViewer>
          {!fullscreen && <GlobalNav />}
          {refreshTokenFlag ? (
            <CheckSession
              auth={Auth}
              onRefreshToken={() => setCanRenderChildren(true)}
              session={Session}
            />
          ) : (
            <SessionDetails Session={Session} />
          )}
          {isUserAuthenticated &&
            (refreshTokenFlag ? canRenderChildren : true) &&
            children}
        </FetchViewer>
      )}
    </Route>
  );
}

export default GuardedRoute;
